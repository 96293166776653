<template>
  <div class="diy-modern-main">
    <div class="d-flex align-items-center justify-content-between">
      <diy-steps-info/>
      <diy-modern-help />
    </div>

    <b-row class="mt-1">
      <b-col cols="12" xl="8" md="8">
        <div class="modern-option-header">
          <div class="d-flex">
            <div class="map-header-fit" v-if="modernBreakDownResults">
              <span><b>Fit: </b></span>
              <span :style="{ color: CalculateModernPopColor(modernBreakDownResults.result) }"> {{ modernBreakDownResults.result }} ({{AncestryFitStatus(modernBreakDownResults.result)}})</span>
            </div>
            <div class="vertical-hr"></div>
            <div class="select-pop-btn" @click="selectPopModalObject.showModal = true">
              <select-pop-icon />
              Select <span class="hidden-mobile">Populations</span>
            </div>
            <div class="vertical-hr hidden-mobile"></div>
            <modern-diy-pop class="hidden-mobile"/>
          </div>
          <div class="d-flex calculate-btn-card">
            <modern-diy-pop class="hidden-desktop mr-2" />
            <button class="lab-btn btn-select-pop mr-2" @click="onCalculate">
              <b-spinner
                v-if="isStartRunning"
                class="mr-1"
                small
                variant="light"
              />
              Calculate
            </button>
          </div>
        </div>
        <div class="diy-card-items" v-if="modernBreakDownResults.outPuts">
          <div 
            v-for="(itemAncestry, index) in modernBreakDownResults.outPuts"
            :key="itemAncestry.resultsTable">
            <div class="modern-breakdown-card child-card d-flex justify-content-between align-items-center" :style="{ backgroundColor: colors[index] }">
              <div class="text-avatar">
                {{ itemAncestry.resultsTable.replaceAll('*', ' ') }}
              </div>
              <div class="text-pop">
                {{ itemAncestry.currentResult.toFixed(1) }}%
              </div>
            </div>
            <div v-for="itemDetay in itemAncestry.subResult" :key="itemDetay.resultsTable">
              <div class="modern-breakdown-card first-child-card d-flex justify-content-between align-items-center">
                <div class="text-avatar" :style="{ color: colorize(colors[index]) }">
                  <feather-icon
                    size="14"
                    class="lab-mr-5"
                    :style="{ color: colors[index] }"
                    icon="CircleIcon"
                  />
                  {{ itemDetay.resultsTable.replaceAll('*', ' ') }}
                </div>
                <div class="text-pop" :style="{ color: colorize(colors[index]) }">
                  {{ itemDetay.currentResult.toFixed(1) }}%
                </div>
              </div>

              <div v-for="itemDetay2 in itemDetay.subResult" :key="itemDetay2.resultsTable">
                <div class="modern-breakdown-card second-child-card d-flex justify-content-between align-items-center">
                  <div class="text-avatar" :style="{ color: colorize(colors[index]) }">
                      <feather-icon
                        size="14"
                        class="lab-mr-5"
                        :style="{ color: colors[index] }"
                        icon="CircleIcon"
                      />
                    {{ itemDetay2.resultsTable.replaceAll('*', ' ') }}
                  </div>
                  <div class="text-pop" :style="{ color: colorize(colors[index]) }">
                    {{ itemDetay2.currentResult.toFixed(1) }}%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>

      <b-col cols="12" xl="4" md="4"  v-if="modernBreakDownResults.outPuts">
        <div class="pop-view-card list-card">
          <div class="dashboard-card pop-view-header">
            <div class="title-header">
              <h4>Summary</h4>
            </div>
          </div>

          <div class="pop-view-body" v-if="modernBreakDownResults != null">
            <div v-for="(itemAncestry, index) in modernBreakDownResults.outPuts"
                :key="itemAncestry.resultsTable">
              <div class="pop-list-item">
                <div class="pop-left" :style="{ color: colorize(colors[index]) }">
                  {{ itemAncestry.resultsTable.replaceAll('*', ' ') }}
                  <div class="pop-progress" :style="{ backgroundColor: ddAlpha(colors[index], 0.1) , width: '100%' }">
                    <div class="pop-progress-fill" :style="{ backgroundColor: colors[index], width: itemAncestry.currentResult.toFixed(1) + '%' }"></div>
                  </div>
                </div>
                <div class="pop-value" :style="{ color: colorize(colors[index]) }">
                  {{itemAncestry.currentResult.toFixed(1)}}%
                </div>
              </div>

            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <ModernDiySelectPopModal :modalObject="selectPopModalObject" v-if="selectPopModalObject.showModal"/>
  </div>
</template>

<script>
import { CalculateModernPopColor } from '@/common/custom-service/dna-source/utils'
import AlertService from '@/common/alert.service'
import ApiService from '@/common/api.service'

import SelectPopIcon from '@/layouts/icons/SelectPopIcon.vue'
import ModernDiyPop from './ModernDiyPop.vue'
import ModernDiySelectPopModal from './ModernDiySelectPopModal.vue'

import DiyModernHelp from './helps/DiyModernHelp.vue'
import DiyStepsInfo from './helps/DiyStepsInfo.vue'

export default {
  components: {
    ModernDiyPop,
    ModernDiySelectPopModal,
    SelectPopIcon,
    DiyModernHelp,
    DiyStepsInfo,
  },
  data() {
    return {
      colors: ['#22B0BD', '#FB8500', '#DF6878', '#83B172', '#A23070', '#FFB703', '#023047', '#4F3F89', '#507694', '#9E8560', 
                '#22B0BD', '#FB8500', '#DF6878', '#83B172', '#A23070', '#FFB703', '#023047', '#4F3F89', '#507694', '#9E8560', 
                '#22B0BD', '#FB8500', '#DF6878', '#83B172', '#A23070', '#FFB703', '#023047', '#4F3F89', '#507694', '#9E8560'],
      isStartRunning: false,
      selectPopModalObject: {
        showModal: false,
      },
    }
  },
  methods: {
    onCalculate() {
      if (this.$store.state.modernbreakdown.modernBreadDownSourceDataActive.find(x => x.isActive)) {
        this.isStartRunning = true;
        setTimeout(() => {
          this.onChangeModernBreakDown();
          this.isStartRunning = false;
        });
      } else {
        AlertService.warning(this, 'Please select population.');
      }
    },

    // Runs
    onChangeModernBreakDown(isInitialize) {
      /* eslint-disable */
      const selectedResultId = this.$store.state.orderResult.selectedResultId;
      const selectedResult = this.$store.state.modernbreakdown.modernBreadDownResults.find(x => x.selectedResultId === selectedResultId);
      if (selectedResult && isInitialize) {
        this.OnRunModernBreakDown();
      } else if (isInitialize) {
        const query = {
          selectedResultId: selectedResultId,
          popNumber: this.$store.state.modernbreakdown.modernBreakDownnPop,
        }
        this.$store.dispatch('modernbreakdown/fetchModernBreakDownSourceDatas', query)
          .then(response => {
            if (response.statusCode === 200) {
              this.$store.commit('modernbreakdown/setModernBreakDownSourceData', response.result);
              
              this.OnRunModernBreakDown();
            } else {
              AlertService.error(this, response.message)
            }
          });
          
      } else {
        this.$store.commit('modernbreakdown/runSingleModernBreakDown');
        const results = this.$store.getters['modernbreakdown/getModernBreakDownResult'];
       
        const saveResultQuery = {
          calculatedResult: JSON.stringify(results),
          orderResultId: this.$store.state.orderResult.selectedResultId,
          popNumber: this.$store.state.modernbreakdown.modernBreakDownnPop,
        };
        
        ApiService.postSilent('result/modernbreakdown', saveResultQuery, this);
        this.OnRunModernBreakDown();
      }
    },
    OnRunModernBreakDown() {
      const results = this.$store.getters['modernbreakdown/getModernBreakDownResult'];
      this.$store.commit('modernbreakdown/setModernBreakDownFinalResults', results)
    },

    // Options
    CalculateModernPopColor,
    AncestryFitStatus(value) {
      if (value > 0 && value <= 1) {
        return 'Very Good';
      }
      if (value > 1 && value <= 3) {
        return 'Good';
      }
      if (value > 3 && value <= 5) {
        return 'Moderate';
      }
      return 'Bad';
    },
    colorize(color) {
      return this.$store.state.appConfig.layout.skin === 'dark' ? color : '#2D2D2D';
    },
    ddAlpha(color, opacity) {
      let _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
      return color + _opacity.toString(16).toUpperCase();
    },
  },
  computed: {
    modernBreakDownResults() {
      const value = this.$store.getters['modernbreakdown/getModernBreakDownFinalResult'];
      
      if(!value) {
        value.outPuts = null;
      }
      return value;
    },
  },
  mounted() {
    this.$root.$refs.ModernDiyModel = this;
  },
}
</script>

<style lang="scss" scoped>
.diy-modern-main {
  margin: 0 auto;
  max-width: 1250px !important;
}

.btn-select-pop {
  background-color: var(--lab-g25-diy-color) !important;
  font-size: 1rem;
}

.modern-option-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--bg-card-color);
  box-shadow: var(--lab-card-shadow);
  border: var(--lab-card-border);
  padding: 1.3rem;
  border-radius: 15px;

  .map-header-fit {
    margin-top: 5px;
  }
  .select-pop-btn {
    margin-top: 5px;
    color: var(--lab-carbon-fiber);
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;

    svg {
      height: 25px;
      width: 25px;
    }
  }
}

.dark-layout .modern-option-header .select-pop-btn  {
  color: var(--lab-g25-diy-color);
}

.modern-breakdown-card {
  background: var(--bg-card-color);
  box-shadow: var(--lab-card-shadow);
  border: var(--lab-card-border);
  padding: 1.4rem;
  border-radius: 10px;
  margin-bottom: 7px;

  .text-avatar {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    font-weight: bold;
  }
  .text-pop {
    font-size: 0.8rem;
    font-weight: bold;
  }
}

.child-card {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: var(--lab-white) !important;
}
.first-child-card {
  width: 95%  !important;
  margin-left: auto;
  color: var(--lab-carbon-fiber);
}
.second-child-card {
  width: 90%  !important;
  margin-left: auto;
  color: var(--lab-carbon-fiber);
}

.diy-card-items {
  margin-top: 1.5rem;
  padding: 0 0 0 3rem;
}

.title-header::before { 
  background-color: var(--lab-g25-diy-color);
}
.dark-layout .title-header h4 {
  color: var(--lab-g25-diy-color);
}

@media screen and (max-width: 769px) { 
  .modern-option-header {
    display: block !important;
  }
  .calculate-btn-card {
    margin-top: 2rem;
  }
}
</style>
