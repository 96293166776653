<template>
  <div class="mb-2">
    <b-row class="mb-1">
       <b-col cols="12" xl="12" md="12" class="mobile-pca-alert-hide">
         <b-alert
            variant="primary"
            show
          >
            <div class="alert-body">
              <small>Turn your mobile device <strong>sideways</strong> while viewing the PCA.</small>
            </div>
          </b-alert>
       </b-col>
      <b-col cols="12" xl="12" md="12">
        <pca-options :onSetRegionGrup="onSetRegionGrup" :onSetRegion="onSetRegion" :isDark="isDark"
              :onClickPcaSkin="onClickPcaSkin" :onClickPcaConvexHull="onClickPcaConvexHull"/>
      </b-col>
    </b-row>

    <b-row>
      <b-col xl="12" md="12" v-show="$store.getters.getLoader">
        <SpinnerContainer />
      </b-col>
      <b-col xl="12" md="12" v-show="!$store.getters.getLoader">
         <div class="tab" id="pcaplot">
            <div id="optX" class="buttongroup" style="display:none">
              <button class="buttons pcleft" onclick="" disabled="true">X:&nbsp;PC1</button>
              <button id="flipX" class="buttons pcright" onclick="toggleOptions('flipX', this)">flip</button>
              <button class="buttons plusminus" onclick="toggleOptions('decDimX', this)">-</button>
              <button class="buttons plusminus" onclick="toggleOptions('incDimX', this)">+</button>
            </div>
            <div id="optY" class="buttongroup" style="display:none">
              <button class="buttons pcleft" onclick="" disabled="true">Y:&nbsp;PC2</button>
              <button id="flipY" class="buttons pcright" onclick="toggleOptions('flipY', this)">flip</button>
              <button class="buttons plusminus" onclick="toggleOptions('decDimY', this)">-</button>
              <button class="buttons plusminus" onclick="toggleOptions('incDimY', this)">+</button>
            </div>
            <div id="optZ" class="buttongroup" style="display:none">
              <button class="buttons pcleft" onclick="" disabled="true">Z:&nbsp;PC2</button>
              <button id="flipZ" class="buttons pcright" onclick="toggleOptions('flipZ', this)">flip</button>
              <button class="buttons plusminus" onclick="toggleOptions('decDimZ', this)">-</button>
              <button class="buttons plusminus" onclick="toggleOptions('incDimZ', this)">+</button>
            </div>
        </div>
      </b-col>
      <b-col xl="12" md="12" v-show="!$store.getters.getLoader">
        <div id="graphDiv"></div>
      </b-col>
    </b-row>
    <b-row>
      <div class="mt-2" style="height:20px;"></div>
    </b-row>
  </div>
</template>

<script>
import PcaOptions from './PcaOptions.vue';

export default {
  components: {
    PcaOptions,
  },
  data() {
    return {
      isDark: false,
      isOpenPca: true,
    };
  },
  methods: {
    onSetRegionGrup(value, isCreated) {
      this.$store.commit('pcaplot/setSelectedPcaRegionGrup', value);
      if (!isCreated) {
        this.$store.commit('pcaplot/setSelectedPcaRegion', null);
      }
    },
    onSetRegion(value) {
      this.isOpenPca = false;
      this.$store.commit('pcaplot/setSelectedPcaRegion', value);
      this.onChangePcaRegion(value);
      this.isOpenPca = true;
    },
    onClickPcaSkin() {
      this.isDark = !this.isDark;
      this.$store.state.pcaplot.pcaSkin = this.isDark ? 'dark' : 'light';
      this.onChangePcaRegion(this.$store.state.pcaplot.selectedPcaRegionId);
    },

    onClickPcaConvexHull() {
      this.onChangePcaRegion(this.$store.state.pcaplot.selectedPcaRegionId);
    },

    // Runs
    onChangePcaRegion(regionId) {
      if (!regionId) {
        regionId = this.$store.getters['pcaplot/getOrderSelectedPcaRegion'];
      }
  
      const regions = this.$store.getters['operation/getPcaRegions'];

      const findRegion = regions.find(x => x.id === regionId);
      if (findRegion) {
        this.$store.dispatch('pcaplot/fetchPcaRegionSourceDatas', { sourceDataId: findRegion.pcaSourceDataId });
      }
    },
  },
  computed: {
  },
  created() {
    const selectedPcaRegion = this.$store.getters['pcaplot/getOrderSelectedPcaRegion'];
    if (!selectedPcaRegion) {
      let isContinue = false;
      const orderResults = this.$store.getters['orderResult/getOrderResults'];
      const selectedOrderResult = orderResults.find(x => x.id === this.$store.state.orderResult.selectedResultId);
      if (selectedOrderResult) {
        if (selectedOrderResult.pcaRegionId) {
          this.onSetRegion(selectedOrderResult.pcaRegionId);
          this.onSetRegionGrup(selectedOrderResult.pcaRegionGrupId, true);
          isContinue = true;
        }
      }

      if (isContinue === false) {
        const pcaRegions = this.$store.getters['operation/getPcaRegions'];
        if (pcaRegions.length > 0) {
          this.onSetRegion(pcaRegions[0].id);
          this.onSetRegionGrup(pcaRegions[0].presetRegionGrupId, true);
        }
      }
    }
  },
  mounted() {
    this.$root.$refs.PcaPlot = this;
  },
};
</script>

<style>
.js-plotly-plot .plotly .main-svg {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  background-clip: border-box;
  border-radius: 0.428rem !important;
  margin-bottom: 2rem;
}

#graphDiv {
  min-height: calc(100vh - 15rem) !important;
  box-sizing: border-box !important;
  margin-top: 0.7em !important;
}

@media (min-width: 450px) {
  .mobile-pca-alert-hide {
    display: none !important;
  }
}
</style>
