<template>
  <div class="pca-options">
    <div class="lab-dropdown region">
      <div class="lab-dropdown-btn" @click="isShowDropdownGroup = !isShowDropdownGroup" ref="dropdownRegionGrup">
        <span class="lab-dropdown-btn-value">{{ selectedRegionGrupName}}</span>
        <span class="lab-dropdown-btn-icon">
          <dropdown-icon />
        </span>
      </div>
      <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdownGroup}">
        <ul>
          <li v-for="regionItem in pcaRegionGrups"
              :key="regionItem.id"
              @click="onSetRegionGrup(regionItem.id)">
              {{ regionItem.grupName }}
          </li>
        </ul>
      </div>
    </div>

    <div class="lab-dropdown region">
      <div class="lab-dropdown-btn" @click="isShowDropdown = !isShowDropdown" ref="dropdownRegion">
        <span class="lab-dropdown-btn-value">{{ selectedRegionName}}</span>
        <span class="lab-dropdown-btn-icon">
          <dropdown-icon />
        </span>
      </div>
      <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdown}">
        <ul>
          <li v-for="regionItem in pcaRegions"
              :key="regionItem.id"
              @click="onSetRegion(regionItem.id)">
              {{ regionItem.title }}
          </li>
        </ul>
      </div>
    </div>

    <b-form-checkbox
      v-model="isDark"
      checked="true"
      class="custom-control-dark check-box"
      name="check-button"
      switch
      @input="onClickPcaSkin()"
    >
      <span class="switch-icon-left">
        <feather-icon icon="SunIcon" />
      </span>
      <span class="switch-icon-right">
        <feather-icon icon="MoonIcon" />
      </span>
    </b-form-checkbox>

    <b-form-checkbox
      v-model="$store.state.pcaplot.isEnableConvexHull"
      checked="true"
      name="check-button"
      class="custom-control-primary check-box"
      switch
      inline
      @input="onClickPcaConvexHull()"
    >
      <h5>Convex Hull</h5>
    </b-form-checkbox>

  </div>
</template>

<script>
import DropdownIcon from '@/layouts/icons/DropdownIcon.vue';

export default {
  components: {
    DropdownIcon,
  },
  props: {
    onSetRegionGrup: {
      type: Function,
      required: true,
    },
    onSetRegion: {
      type: Function,
      required: true,
    },
    onClickPcaSkin: {
      type: Function,
      required: true,
    },
    onClickPcaConvexHull: {
      type: Function,
      required: true,
    },
    isDark: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isShowDropdownGroup: false,
      isShowDropdown: false,
    }
  },
  methods: {
    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdownRegionGrup;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdownGroup = false;
        }
      } catch (error) {}

      try {
        const el = this.$refs.dropdownRegion;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdown = false;
        }
      } catch (error) {}
    },
  },
  computed: {
    pcaRegionGrups() {
      return this.$store.getters['operation/getPcaRegionGrups'].filter(x => x.dataVersion === this.$store.getters['orderResult/getDataVersion']);;
    },
    pcaRegions() {
      const regions = this.$store.getters['operation/getPcaRegions'];

      return regions.filter(x => x.presetRegionGrupId === this.$store.getters['pcaplot/getOrderSelectedPcaRegionGrup']);
    },

    selectedRegionName() {
      const regionId = this.$store.getters['pcaplot/getOrderSelectedPcaRegion'];
      const regions = this.$store.getters['operation/getPcaRegions'];

      const findRegion = regions.find(x => x.id === regionId);

      return findRegion != null ? findRegion.title : 'Select Region';
    },
    selectedRegionGrupName() {
      const regionGrupId = this.$store.getters['pcaplot/getOrderSelectedPcaRegionGrup'];

      const findRegionGrup = this.pcaRegionGrups.find(x => x.id === regionGrupId);

      return findRegionGrup != null ? findRegionGrup.grupName : 'Select Region';
    },
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
}
</script>

<style lang="scss" scoped>
.dark-layout .lab-dropdown .lab-dropdown-btn {
  color: var(--lab-g25-genetic-dist-color);
  border: 2px solid var(--lab-g25-genetic-dist-color);
}

.dark-layout .lab-dropdown.region .lab-dropdown-btn svg {
  fill: var(--lab-g25-genetic-dist-color);
}

.pca-options {
  display: flex;
  align-items: center;
}

.region {
  margin-right: 10px;
}

.check-box {
  margin-right: 10px;
}

@media (max-width: 450px) {
  .region {
    width: 50% !important;
  }

  .check-box {
    width: 50% !important;
  }
}
</style>
