<template>
  <div>
    <genetic-distance-modern-help />
    <b-row class="mt-1">
      <b-col cols="12" xl="8" md="7"  class="mt-2">
        <div class="number-card">
          <div class="number-card-header">
            <h4 class="genetice-dist-text"> {{ $t('Number Of Populations') }}</h4>
            <a @click="onOpenFilterModal();">
              <filter-icon />
            </a>
          </div>
          <div class="number-card-body d-flex align-items-center row mt-1">
            <b-col cols="12" lg="10" md="10" class="slider-container">
              <vue-slider
                v-model="$store.state.modernpopulation.closestModerPopNumber"
                :adsorb="true"
                :data="sliderData"
                :marks="true"
                @change="onChangeModernPop"
                direction="ltr"
                class="genetic-dist-slider"
              />
            </b-col>
            <b-col cols="12" lg="2" md="2" class="hidden-mobile">
              <div class="lab-timeline-switch">
                <div class="timeline-item" @click="switchLeft()">
                  <b-img :src="'https://image.illustrativedna.com/assets/icons/time_line_left.svg'" />
                </div>
                <div class="timeline-item last"  @click="switchRight()">
                  <b-img :src="'https://image.illustrativedna.com/assets/icons/time_line_right.svg'" />
                </div>
              </div>
            </b-col>
          </div>
        </div>
        <div class="map-card mt-2" v-if="$store.state.appConfig.layout.windowWidth >= 769">
            <modern-distance-map v-if="dataVersion === 'D-01' && modernPopGeoJson" 
              :geoJsonData="modernPopGeoJson" 
              :modernPopData="closestModernPopResults" />
            <modern-distance-map-v2 v-if="dataVersion === 'D-02' && closestModernPopResults && showMap"  
              :modernPopData="closestModernPopResults" />
        </div>
      </b-col>
      <b-col cols="12" xl="4" md="5"  class="mt-2">
        <div class="modern-pop-container">
          <div class="modern-pop-container-top">
            <div class="modern-pop-container-header">
              <h4 class="genetice-dist-text">List View</h4>
            </div>
          </div>

          <SpinnerContainer v-if="$store.getters.getLoader" class="ancient-body vh-80"/>
          <div class="modern-pop-container-body  cs-scroll-style" v-else>
            <b-table
              :fields="fields"
              :items="closestModernPopResults"
              responsive="sm"
              sticky-header="600px"
              class="cs-scroll-style"
              head-variant="light"
              tbody-tr-class="closest-ancient-card"
            >
              <template #cell(no)="data">
               <span> {{ data.index + 1 }}</span>
              </template>

              <template #cell(title)="data">
                <span style="font-size:0.8rem"> 
                  {{ data.item.title.replaceAll('*', ' ') }}
                </span>
              </template>

              <template #cell(distance)="data">
                <span :style="{ color: data.item.gradHSL }"> {{ data.item.distance }}</span>
              </template>
            </b-table>
          </div>
        </div>
      </b-col>
    </b-row>

    <ModernSouceDataSelection v-if="modalObject.showModal" :modalObject="modalObject"/>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import Ripple from 'vue-ripple-directive'

/* eslint-disable global-require */
import FilterIcon from '@/layouts/icons/FilterIcon.vue';

import ModernDistanceMap from './maps/ModernDistanceMap.vue'
import ModernDistanceMapV2 from './maps/ModernDistanceMapV2.vue'

import ModernSouceDataSelection from '../../pages/ModernSouceDataSelection.vue';
import GeneticDistanceModernHelp from './helps/GeneticDistanceModernHelp.vue';

export default {
  components: {
    VueSlider,
    ModernDistanceMap,
    ModernDistanceMapV2,
    ModernSouceDataSelection,
    FilterIcon,
    GeneticDistanceModernHelp,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      sliderData: ['10', '20', '30', '40', '50'],
      fields: [
        {
          key: 'no',
          label: '#',
          thClass: 'th-no',
        },
        {
          // A regular column with custom formatter
          key: 'title',
          label: 'Population',
        },
        {
          // A virtual column with custom formatter
          key: 'distance',
          label: 'distance',
          class: 'text-center',
          tdClass: 'td-no',
        },
      ],
      showMap: true,

      modalObject: {
        showModal: false,
        onSuccess: this.onChangeModernPop,
      },
    }
  },
  methods: {
    /* eslint-disable */
    onInitModernPop() {
      this.showMap = false;
      this.$store.commit('operation/setMdernPopGeoJsonResult', null);
      setTimeout(() => { 
        this.$store.dispatch('modernpopulation/fetchModernSourceDatas', { sourceDataType: 2 })
          .then(() => {
            this.showMap = true;
            this.$store.commit('modernpopulation/runModernDist')
            if (typeof callback === 'function') {
              callback();
            }
            this.OnRunModernMap();
          })
      });
    },
    onChangeModernPop() {
      this.$store.commit('operation/setMdernPopGeoJsonResult', null);
      setTimeout(() => { 
        this.onChangeClosestModernPop();

        if (this.$root.$refs.ModernDistanceMapV2) {
          this.$root.$refs.ModernDistanceMapV2.onLoadCoordinates();
        }
      });
    },

    onChangeClosestModernPop(callback) {
      if (this.$store.state.modernpopulation.modernSourceDatas.length) {
        this.$store.commit('modernpopulation/setModernSourceDatas', [])
        this.$store.commit('modernpopulation/runModernDist')

        if (typeof callback === 'function') {
          callback();
        }
        this.OnRunModernMap();
      } else {
        this.$store.dispatch('modernpopulation/fetchModernSourceDatas', { sourceDataType: 2 })
          .then(() => {
            this.$store.commit('modernpopulation/runModernDist')
            if (typeof callback === 'function') {
              callback();
            }
            this.OnRunModernMap();
          })
      }
    },
    OnRunModernMap() {
      const results = this.$store.getters['modernpopulation/getClosestModernPopResults'];
      this.OnFetchModerMap(() => {
        const resultDistance = results.map(item => item.title);
        const mapGeoJson = this.$store.getters['operation/getModernPopGeoJson'];
        
        const resultGeoJsons = [];
        mapGeoJson.features.forEach(value => {
          if (resultDistance.includes(value.properties['pop-id'])) {
            if (value.properties['sub-id']) {
              value.properties.gx_id = value.properties['pop-id'] + value.properties['sub-id'];
              value.properties.sub_id = value.properties['sub-id'];
            } else {
              value.properties.gx_id = value.properties['pop-id'];
            }
            
            resultGeoJsons.push(value);
          }
        });

        this.$store.commit('operation/setMdernPopGeoJsonResult', { "type": "FeatureCollection", "features": resultGeoJsons });
      });
    },
    OnFetchModerMap(callback) {
      if (this.$store.getters['operation/getModernPopGeoJson'] == null) {
        this.$store.dispatch('operation/fetchMapSourceModern', { }).then(response => {
          if(response.statusCode === 200) {
            this.$store.commit('operation/setMdernPopGeoJson', response.result)
            if (typeof callback === 'function') {
              callback();
            }
          }
        });
      } else {
        if (typeof callback === 'function') {
          callback();
        }
      }
    },

    onOpenFilterModal() {
      this.modalObject.showModal = true;
    },

    switchLeft() {
      /* eslint-disable */
      if (this.$store.state.modernpopulation.closestModerPopNumber != '10') {
        this.$store.state.modernpopulation.closestModerPopNumber = (parseInt(this.$store.state.modernpopulation.closestModerPopNumber) - 10).toString();
        this.onChangeModernPop();
      }
    },
    switchRight() {
      /* eslint-disable */
      if (this.$store.state.modernpopulation.closestModerPopNumber != '50') {
        this.$store.state.modernpopulation.closestModerPopNumber = (parseInt(this.$store.state.modernpopulation.closestModerPopNumber) + 10).toString();
        this.onChangeModernPop();
      }
    },
  },
  computed: {
    closestModernPopResults() {
      return this.$store.getters['modernpopulation/getClosestModernPopResults'];
    },
    modernPopGeoJson() {
      return this.$store.getters['operation/getModernPopGeoJsonResult'];
    },
    dataVersion() {
      return this.$store.getters['orderResult/getDataVersion'];
    },
  },
  mounted() {
    this.$root.$refs.ModernGeneticDistance = this;
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-slider.scss';
.genetic-dist-slider .vue-slider-dot-handle {
  border: 2px solid var(--lab-g25-genetic-dist-color) !important;
}

.genetic-dist-slider .vue-slider-dot-tooltip-inner {
  background-color: var(--lab-g25-genetic-dist-color) !important;
  border-color: var(--lab-g25-genetic-dist-color) !important;
}
.genetic-dist-slider .vue-slider-dot-handle {
  border: 2px solid var(--lab-g25-genetic-dist-color) !important;

  &-focus {
    box-shadow: 0px 0px 1px 2px #e27d955c !important;
  }
}

.genetic-dist-slider .vue-slider-process {
  background-color: var(--lab-g25-genetic-dist-color) !important;
}

.genetic-dist-slider .vue-slider-rail {
  background-color:  #e27d955c !important;
}

.genetic-dist-slider .vue-slider-dot-tooltip-inner {
  background-color: var(--lab-g25-genetic-dist-color) !important;
  border-color: var(--lab-g25-genetic-dist-color) !important;
}

.modern-pop-container-body {
  .closest-ancient-card td,th {
    padding: 0.7rem !important;
  }
}
</style>

<style lang="scss" scoped>
.th-no {
  max-width: 5px !important;
  padding-top: 15px !important;
  padding-right: 5px !important;
}

.b-table-sticky-header {
  overflow-x: hidden !important;
}

.map-card {
  background: var(--bg-card-color);
  box-shadow: var(--lab-card-shadow);
  padding: 1.1rem;
  border-radius: 10px;

  .map-header {
    padding: 0 0 0.8rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .map-body {
    border-radius: 5px;
  }
}

.modern-pop-container {
  background: var(--bg-card-color);
  box-shadow: var(--lab-card-shadow);
  border-radius: 5px;

  .modern-pop-container-top {
    padding: 2rem 0rem;
  }
  .modern-pop-container-header {
    display: flex;
    align-items: center;
    padding: 0 2rem;
    h4 {
      color: var(--lab-carbon-fiber);
      font-weight: 600;
      font-size: 1rem;
      margin: 0;
    }
  }

  .modern-pop-container-header::before { 
    content: '';
    margin-right: 5px;
    height: 20px;
    width: 7px;
    background-color: var(--lab-g25-genetic-dist-color);
  }

  .modern-pop-container-body {
    max-height: 700px;
    overflow: auto !important;
    overflow-x: hidden !important;
    padding: 0px 20px 20px 20px;
  }
}

.dark-layout .modern-pop-container  {
 .modern-pop-container-header {
  color: var(--lab-g25-genetic-dist-color);

    h4 {
      color: var(--lab-g25-genetic-dist-color);
    }
 }
}

.number-card-header {
  svg {
    height: 30px;
    width: 30px; 
    color: var(--lab-g25-genetic-dist-color) !important;
    cursor: pointer;
  }
}

.slider-container {
  width: 90%;
  padding: 0 1rem;
}
.lab-timeline-switch {
  display: flex;
  align-items: center;
  justify-content: end;
  
  .timeline-item {
    background-color: var(--lab-g25-genetic-dist-color) !important;
  }
}
</style>
