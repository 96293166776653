<template>
  <div class="d-flex align-items-center justify-content-between">
    <div class="lab-dropdown sm region">
      <div class="lab-dropdown-btn" @click="isShowDropdownGroup = !isShowDropdownGroup" ref="dropdownRegionGrup">
        <span class="lab-dropdown-btn-value">
          {{ getSelectedRegionGrupName.length > 16 ? getSelectedRegionGrupName.slice(0, 16) + '..' : getSelectedRegionGrupName}}
        </span>
        <span class="lab-dropdown-btn-icon">
          <dropdown-icon />
        </span>
      </div>
      <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdownGroup}">
        <ul>
          <li v-for="regionItem in mapRegionGrups"
              :key="regionItem.id"
              @click="onSetRegionGrup(regionItem.id)">
              {{ regionItem.grupName }}
          </li>
        </ul>
      </div>
    </div>
    <div class="lab-dropdown sm region ml-2 mr-2">
      <div class="lab-dropdown-btn" @click="isShowDropdown = !isShowDropdown" ref="dropdownRegion">
        <span class="lab-dropdown-btn-value">
          {{ selectedRegionName.length > 16 ? selectedRegionName.slice(0, 16) + '..' : selectedRegionName}}
        </span>
        <span class="lab-dropdown-btn-icon">
          <dropdown-icon />
        </span>
      </div>
      <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdown}">
        <ul>
          <li v-for="regionItem in mapRegions"
              :key="regionItem.id"
              @click="onSetRegion(regionItem.id)">
              {{ regionItem.title }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownIcon from '@/layouts/icons/DropdownIcon.vue';

export default {
  components: {
    DropdownIcon,
  },
  props: {
    runAncestryBreakDown: {
      type: Function,
      required: true,
    },
    selectedRegionName: {
      type: String,
      required: true,
    },
    mapRegions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isShowDropdownGroup: false,
      isShowDropdown: false,
    }
  },
  methods: {
    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdownRegionGrup;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdownGroup = false;
        }
      } catch (error) {}

      try {
        const el = this.$refs.dropdownRegion;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdown = false;
        }
      } catch (error) {}
    },

    onSetRegionGrup(value) {
      this.$store.commit('ancestrybreakdown/setSelectedRegionGrup', value);
    },
    onSetRegion(value) {
      this.$store.commit('operation/setAncestryGeoJson', []);
      this.$store.commit('ancestrybreakdown/setSelectedRegion', value);
      this.runAncestryBreakDown(value);
    },
  },
  computed: {
    mapRegionGrups() {
      return this.$store.getters['operation/getRegionGrups'].filter(x => x.dataVersion === this.$store.getters['orderResult/getDataVersion']);
    },
    getSelectedRegionGrupName() {
      const regionGrupId = this.$store.getters['ancestrybreakdown/getOrderSelectedRegionGrup'];

      const findRegionGrup = this.mapRegionGrups.find(x => x.id === regionGrupId);

      return findRegionGrup != null ? findRegionGrup.grupName : 'Select Region';
    },
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
}
</script>

<style lang="scss" scoped>
.lab-dropdown .lab-dropdown-content {
  width: 100%;
  min-width: 130px;
}

.dark-layout .lab-dropdown .lab-dropdown-btn {
  color: var(--lab-lago-blue-color);
  border: 2px solid var(--lab-lago-blue-color);
}

.dark-layout .lab-dropdown.region .lab-dropdown-btn svg {
  fill: var(--lab-lago-blue-color);
}
</style>
