<template>
  <div>
    <div class="pop-view-card" :class="{ 'list-card': listView === true }">
      <div class="dashboard-card pop-view-header">
        <div class="title-header">
          <h4>Summary</h4>
        </div>
        <label class="lab-switch">
          <input type="checkbox" v-model="listView">
          <span class="slider round"></span>
        </label>
      </div>

      <div class="pop-view-body" v-if="regionResults && !$store.getters.getLoader">
        <div v-for="itemAncestry in regionResults.outPuts"
            :key="itemAncestry.resultsTable">

          <div @click="onOpenDetailModal(itemAncestry)"
            class="dashboard-card pop-grid-item" v-if="listView === false">
            <b-media no-body>
              <b-media-aside class="mr-75">
                <b-avatar
                  rounded
                  size="70"
                  :src="itemAncestry.pictureUrl"
                />
              </b-media-aside>
              <b-media-body class="my-auto">
                <h6 class="mb-0">
                  {{ itemAncestry.resultsTable.replaceAll('*', ' ') }} 
                </h6>
              </b-media-body>
            </b-media>
            <div class="d-flex align-items-center lab-p-8">
              <span class="mr-75"><b>{{ itemAncestry.currentResult.toFixed(1) }}%</b></span>

              <!-- chart -->
              <vue-apex-charts
                type="radialBar"
                height="40"
                width="40"
                :options="onGetChartOption(itemAncestry.color)"
                :series="[itemAncestry.currentResult.toFixed(1)]"
              />
            </div>
          </div>

          <div @click="onOpenDetailModal(itemAncestry)"
            class="pop-list-item" v-else>
            <div class="pop-left" :style="{ color: colorize(itemAncestry.color) }">
              {{ itemAncestry.resultsTable.replaceAll('*', ' ') }}
              <div class="pop-progress" :style="{ backgroundColor: ddAlpha(itemAncestry.color, 0.1) , width: '100%' }">
                <div class="pop-progress-fill" :style="{ backgroundColor: itemAncestry.color, width: itemAncestry.currentResult.toFixed(1) + '%' }"></div>
              </div>
            </div>
            <div class="pop-value" :style="{ color: colorize(itemAncestry.color) }">
              <span>{{itemAncestry.currentResult.toFixed(1)}}%</span>
            </div>
          </div>

        </div>
      </div>
      <SpinnerContainer v-else/>
    </div>

    <GeoMapDetailModal v-if="modalObject.showModal" :modalObject="modalObject" />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import GeoMapDetailModal from '../../../maps/GeoMapDetailModal.vue'

export default {
  components: {
    VueApexCharts,
    GeoMapDetailModal,
  },
  directives: {
  },
  data() {
    return {
      listView: false,
      modalObject: {
        item: null,
        showModal: false,
      },
    }
  },
  methods: {
    /* eslint-disable */
    onChangeViewSwitch(event) {
      this.listView = event ? 'LIST-VIEW' : 'GRID-VIEW';
    },
    onGetChartOption(color) {
      const barColor = color ? color : '#00ADB5';
      return {
        grid: {
          show: false,
          padding: {
            left: -15,
            right: -15,
            top: -12,
            bottom: -15,
          },
        },
        colors: [barColor],
        plotOptions: {
          radialBar: {
            hollow: {
              size: '22%',
            },
            track: {
              background: this.ddAlpha(color, 0.1),
            },
            dataLabels: {
              showOn: 'always',
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
      };
    },
    ddAlpha(color, opacity) {
      let _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
      return color + _opacity.toString(16).toUpperCase();
    },
    colorize(color) {
      return this.$store.state.appConfig.layout.skin === 'dark' ? color : '#2D2D2D';
    },
    onOpenDetailModal(item) {
      item.name = item.resultsTable.replaceAll('*', ' ');
      this.modalObject.item = item;
      this.modalObject.showModal = true;
    },
  },
  computed: {
    regionResults() {
      return this.$store.getters['ancestrybreakdown/getRegionFinalResults'];
    },
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.title-header::before { 
  background-color: var(--lab-turquoise-sea);
}
.dark-layout .title-header h4 {
  color: var(--lab-turquoise-sea);
}

.dark-layout .pop-view-card h6, 
.dark-layout .pop-view-card .pop-view-body .pop-list-item .pop-left,
.dark-layout .pop-view-card .pop-view-body .pop-list-item .pop-value  {
  color: var(--lab-light-house);
}

.lab-switch input:checked + .slider {
  background-color: var(--lab-turquoise-sea);
}
</style>
